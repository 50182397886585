import React from 'react';
import '../css/fenykepek.css';
import Carousel from '../components/carousel';
import { Link } from 'react-router-dom';

function Fenykepek() {
    return (
        <div className="container-md-fluid kartyak">
            {/* Navigation Buttons */}
            <div className="d-flex justify-content-center mb-4 flex-wrap">
                <div className="me-3 mb-2">
                    <Link className="btn w-auto" to="/">Kezdőlap</Link>
                </div>
                <div className="mb-2">
                    <Link className="btn w-auto" to="/leiras">Leírás</Link>
                </div>
            </div>

            {/* Cards Section */}
            <div className="row row-cols-lg-4 row-cols-md-2 row-cols-2 g-4 justify-content-center">
                <div className="col">
                    <div className="card">
                        <img className="card-img-top img-fluid" src={require("../kepek/kaszni.png")} alt="kaszni" />
                            <Link to="/kaszni" className="btn">Kaszni</Link>
                    </div>
                </div>

                <div className="col">
                    <div className="card">
                        <img className="card-img-top img-fluid" src={require("../kepek/muszerfal_tabla.png")} alt="interior" />
                            <Link to="/interior" className="btn">Kárpit</Link>
                    </div>
                </div>

                <div className="col">
                    <div className="card">
                        <img className="card-img-bottom img-fluid" src={require("../kepek/futomu01.jpg")}
                             style={{borderRadius: "25px"}} alt="futomu" />
                            <Link to="/suspension" className="btn">Futómű</Link>
                    </div>
                </div>

                <div className="col">
                    <div className="card">
                        <img className="card-img-top img-fluid" src={require("../kepek/hamarosan.png")} alt="hamarosan" />
                            <Link to="/movies" className="btn">Videok</Link>
                    </div>
                </div>

                {/* Carousel Component */}
                <div className="col carousel">
                    <Carousel />
                </div>
            </div>
        </div>
    );
}

export default Fenykepek;
